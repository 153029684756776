import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ProductComp() {
  return (
    // className="component-bottom-margin"
    <Row>
      <Col xs={12} className="py-5">
        <p className="subheading text-center">The AI Adventure</p>
        <h3 className="size-40 weight-700 second-color line-height-120 text-center">
          Upgrade yourself & your Institute with Scool.Ai
        </h3>
        <div className="d-flex justify-content-center align-items-center w-100">
          <p className="detail2 text-center">
            Enhance your educational journey with School.ai’s innovative
            applications, each designed to cater to different aspects of the
            learning and management process. Whether you're seeking better
            engagement, professional growth, or streamlined operations, our apps
            provide the solution
          </p>
        </div>
      </Col>
      {/* <Col xs={12} className="d-flex justify-content-center align-items-center">
        <div>
          <img
            src={Image2}
            alt="image1"
            className="img-fluid image-shadow-1"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </Col> */}
    </Row>
  );
}

export default ProductComp;
