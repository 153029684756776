import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Award1 from "./assets/logo1.png";
import Award2 from "./assets/logo2.png";
import Award3 from "./assets/logo3.png";
import Award4 from "./assets/logo4.png";
import Award5 from "./assets/logo5.png";

function Awards() {
  return (
    <div className="component-bottom-margin-2">
      <Container fluid="xxl">
        <Row className="d-flex justify-content-center">
          <p className="subheading text-center mb-3">Our Participations</p>
          <h3
            className="text-center size-40 weight-700 second-color line-height-120 content-bottom"
            style={{ maxWidth: "850px" }}
          >
            Scool.Ai participated at internation events and raised funds from
            top organizations!
          </h3>
        </Row>
      </Container>
      <Container fluid="xxl" style={{ maxWidth: "1100px" }}>
        <Row className="d-flex justify-content-center">
          <Col xs={6} md={3} className="text-center">
            <img src={Award1} alt="Award 1" className="award" />
          </Col>
          <Col xs={6} md={3} className="text-center">
            <img src={Award2} alt="Award 2" className="award" />
          </Col>
          <Col xs={6} md={3} className="text-center">
            <img src={Award3} alt="Award 3" className="award" />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs={6} md={3} className="text-center">
            <img src={Award4} alt="Award 1" className="award" />
          </Col>
          <Col xs={6} md={3} className="text-center">
            <img src={Award5} alt="Award 2" className="award" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Awards;
