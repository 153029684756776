import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutPng from "./pageComponents/assets/aboutPng2.png";
import VisionPng from "./pageComponents/assets/about.png";
import GoalPng from "./pageComponents/assets/goal.png";
import Team from "./pageComponents/team";
import { IconArrowBadgeRightFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import TextAnimation from "./pageComponents/textAnimation";
import Carousel from "react-bootstrap/Carousel";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const textArray = [
    "Build with Scool.Ai’s technology to deliver education to students faster.",
    "Grow your institue with School Ai resources and support to help drive demand.",
    "Increase profits and student numbers by capturing more opportunities.",
  ];
  const duration = 2000;
  const handleClick = () => {
    navigate("/book-demo");
  };

  return (
    <>
      <div className="special-bg-3 component-bottom-margin-2">
        <Container fluid="xxl">
          <Row>
            <Col xs={12} md={6}>
              <div className="py-5 px-5">
                <p className="subheading mb-3">About us</p>
                <h3 className="d-flex hero-font weight-700 font-color-primary">
                  Value Statements
                </h3>
                <p className="size-18">
                  <li>
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Inclusivity - Ensuring every student, irrespective of
                    geographic or socio-economic status, has access to the best
                    educational resources.
                  </li>
                  <li>
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Innovation - Continuously advancing our AI solutions to stay
                    at the cutting edge of educational technology.
                  </li>
                  <li>
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Collaboration - Partnering with educators, institutions, and
                    policymakers worldwide to tailor our technology to meet
                    diverse educational needs.
                  </li>
                  <li>
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Empowerment - Equipping students and teachers with the tools
                    they need for success, fostering independence through
                    supportive technologies.
                  </li>
                  <li>
                    <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
                    Integrity - Committing to ethical AI use, prioritizing the
                    safety and privacy of our users while promoting transparency
                    in our operations.
                  </li>
                </p>
                {/* <p className="size-18">
                  Learning is how people evolve, how they develop new skills,
                  and how they generate creative new ideas. And it’s how
                  businesses grow, innovate, and compete.
                </p> */}
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={AboutPng}
                  alt="image1"
                  className="img-fluid image-shadow-1"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid="xxl">
        <Row className="component-bottom-margin responsive-flex">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={VisionPng}
                alt="image1"
                className="img-fluid image-shadow-1"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs={12} md={6} className="py-5">
            <p className="subheading">About</p>
            <h3 className="size-40 weight-700 second-color line-height-120">
              Our Goal
            </h3>
            <p className="detail">
              To lead the global education revolution by integrating AI into
              every aspect of learning. By 2044, we envision a world where our
              AI-driven platforms empower educators and students in every corner
              of the globe, fostering a community where technology and education
              converge to unlock human potential.
            </p>
          </Col>
        </Row>
        <Row className="component-bottom-margin">
          <Col xs={12} md={6} className="py-5">
            <p className="subheading">Inovating Schools</p>
            <h3 className="size-40 weight-700 second-color line-height-120">
              Our Mission
            </h3>
            <p className="detail">
              Scool.Ai aims to revolutionize global education with advanced AI
              technology, providing personalized learning experiences to enhance
              student engagement and success, ensuring accessibility to quality
              education for all.
            </p>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img
                src={GoalPng}
                alt="image1"
                className="img-fluid image-shadow-2"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
        </Row>
        <Row className="component-bottom-margin-2 mx-1">
          <div className="special-bg px-3">
            <h3 className="text-center size-40 weight-700 second-color margin-1 line-height-120">
              Go further, faster, together
            </h3>
            <div className="d-flex justify-content-center flex-column align-items-center text-color size-18 ">
              <p className="text-center" style={{ maxWidth: "800px" }}>
                School.AI provides the tools, technology, and support designed
                to scale, drive revenue, and stand out.
              </p>
              <span className="second-color">
                <TextAnimation textArray={textArray} duration={duration} />
              </span>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <button
                className="main-button-reverse weight-600 mt-3"
                onClick={handleClick}
              >
                Get a Free Demo
              </button>
            </div>
          </div>
        </Row>
      </Container>
      <Container fluid className="special-bg-2 component-bottom-margin-2">
        <Container fluid="xxl">
          <Row className="d-flex justify-content-center ">
            <p className="subheading text-center mb-3">Scool X Ai</p>
            <h3 className="text-center size-40 weight-700 second-color line-height-120">
              Unlock Potential with Scool.Ai
            </h3>

            <Carousel>
              <Carousel.Item interval={2000}>
                <section className="px-3">
                  <div className="px-3">
                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <p className="heading-paragraph">
                          Enhanced Communication - Strengthens community by
                          improving interaction among institutes, parents, and
                          students, fostering a supportive and inclusive
                          educational environment.
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                </section>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <section className="px-3">
                  <div className="px-3">
                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <p className="heading-paragraph">
                          Personalized Learning - AI-driven adaptive systems
                          enhance educational outcomes for students, streamline
                          operations for institutes, and keep parents informed
                          and engaged.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
                <section className="px-3">
                  <div className="px-3">
                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <p className="heading-paragraph">
                          Operational Efficiency - Automates administrative
                          tasks, reducing costs and freeing up resources for
                          schools, while providing parents and students with
                          easy access to essential information.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </Carousel.Item>
            </Carousel>
          </Row>
          {/* <Row className="d-flex justify-content-center align-items-center px-5">
            <img
              src={Image1}
              alt="image1"
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Row> */}
        </Container>
      </Container>
      <Team />
    </>
  );
}

export default About;
