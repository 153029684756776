import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Desc from "./assets/desc.png";
// import Product from "./assets/feature.png";
import Product from "./assets/item.mp4";
import Why from "./assets/why.png";
import What from "./assets/what.png";
import Manage from "./assets/manage.png";
import { IconArrowRight, IconArrowBadgeRightFilled } from "@tabler/icons-react";
import BookDemoComp from "./bookDemoComp";
import { Link } from "react-router-dom";

function OurPlatform() {
  return (
    <Container fluid="xxl">
      <Row className="component-bottom-margin responsive-flex">
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <video
              src={Product}
              autoPlay
              loop
              muted
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="py-5">
          <h2 className="subheading">Scool.Ai</h2>
          <h3 className="size-40 weight-700 second-color line-height-120">
            Product Information
          </h3>
          <p className="detail">
            Scool.Ai offers a revolutionary platform tailored to enhance
            educational systems through advanced AI integration, providing
            solutions that adapt to the unique needs of every educational
            institution.
          </p>
          <Link to="/about" style={{ textDecoration: "none" }}>
            <p className="d-flex learn-more main-color cursor-pointer">
              Learn More
              <span className="d-flex" style={{ marginLeft: "10px" }}>
                <IconArrowRight className="right-arrow" stroke={2} />
              </span>
            </p>
          </Link>
        </Col>
      </Row>
      <Row className="component-bottom-margin">
        <Col xs={12} md={6} className="py-5">
          <h2 className="subheading">Descriptions</h2>
          <h3 className="size-40 weight-700 second-color line-height-120">
            What Does Scool.Ai Do?
          </h3>
          <p className="detail">
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Personalized Learning Experiences - Adapting to student's
              individual learning styles.
            </li>
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Operational Efficiency - Automating administrative tasks to save
              time and resources.
            </li>
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Comprehensive Analytics - Providing insights into student
              performance and institutional effectiveness.
            </li>
          </p>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={What}
              alt="Scool.Ai Technology"
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
      </Row>
      <Row className="component-bottom-margin responsive-flex">
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={Why}
              alt="Scool.Ai Technology"
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="py-5">
          <h2 className="subheading">Core Advantages</h2>
          <h3 className="size-40 weight-700 second-color line-height-120">
            Why Scool.Ai?
          </h3>
          <p className="detail">
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Pioneering AI Technology - Leading the way in educational
              innovation.
            </li>
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Customized Solutions - Tailored to meet the specific needs of
              schools and educational institutions.
            </li>
            <li>
              {" "}
              <IconArrowBadgeRightFilled style={{ color: "#0068d9" }} />
              Global Impact - Making a difference in education on a worldwide
              scale.
            </li>
          </p>
        </Col>
      </Row>
      <Row className="component-bottom-margin">
        <Col xs={12} md={6} className="py-5">
          <h2 className="subheading">Description</h2>
          <h3 className="size-40 weight-700 second-color line-height-120">
            AI Education-Tech
          </h3>
          <p className="detail">
            An all-in-one integrated Educational Technology Platform, Scool.Ai
            is your partner in transforming educational experiences through
            advanced AI integration. We provide solutions that tailor education
            to individual needs, ensuring every learner achieves their full
            potential.
          </p>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={Desc}
              alt="Scool.Ai Technology"
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
      </Row>
      <Row className="component-bottom-margin responsive-flex">
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={Manage}
              alt="Scool.Ai Technology"
              className="img-fluid image-shadow-2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Col>
        <Col xs={12} md={6} className="py-5">
          <h2 className="subheading">Operational Efficiency</h2>
          <h3 className="size-40 weight-700 second-color line-height-120">
            Proactively Manage Your Institute's Operations
          </h3>
          <p className="detail">
            Achieve efficiency through saving time, reducing costs, and building
            trust. Businesses across industries rely on School.ai to automate
            their educational operations.
          </p>
        </Col>
      </Row>
      <BookDemoComp />
    </Container>
  );
}

export default OurPlatform;
