import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logos from "./pageComponents/logos";
import Form from "react-bootstrap/Form";
import { createDemo } from "../../api";
import { toast } from "react-toastify";
import { getNames } from "country-list";
import { IconArrowBadgeRightFilled } from "@tabler/icons-react";
import DemoPng from "./pageComponents/assets/demo.png";

function BookDemo() {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    title: "",
    schoolName: "",
    address: "",
    country: "",
    type: "Demo",
  });
  const countries = getNames();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const responseData = await createDemo(formData);
        toast.success(responseData.message);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error 404");
        }
      }
    }
  };

  const validateForm = () => {
    const { email, firstName, lastName, title, address, schoolName, country } =
      formData;
    const newErrors = {};

    // Business email validation
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // if (!email || !emailPattern.test(email) || email.endsWith("gmail.com")) {
    //   newErrors.email = "Please enter a valid business email address.";
    // }

    if (!email) newErrors.email = "Email is required.";
    if (!firstName) newErrors.firstName = "First name is required.";
    if (!lastName) newErrors.lastName = "Last name is required.";
    if (!title) newErrors.title = "Title is required.";
    // if (!address) newErrors.address = "Address is required.";
    if (!schoolName) newErrors.schoolName = "Institue Name is required.";
    if (!country) newErrors.country = "Country is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <div className="book-demo-body p-5 component-bottom-margin custom-padding-resp">
        <Container fluid="xxl">
          <Row>
            <Col xs={12} md={7}>
              <div className="py-5 px-2 font-white">
                <p className="d-flex hero-font weight-700 extrabold-font">
                  GO FURTHER, FASTER, TOGETHER.
                </p>
                <p className="size-18">
                  Scool.Ai provides the tools, technology, and support designed
                  to scale, drive revenue, and stand out.
                </p>

                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Build with Scool.Ai’s technology to deliver education to
                  students faster.
                </li>
                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Grow your institute with Scool.Ai resources and support to
                  help drive demand.
                </li>
                <li style={{ color: "white" }}>
                  {" "}
                  <IconArrowBadgeRightFilled style={{ color: "white" }} />
                  Increase profits and student numbers by capturing more
                  opportunities.
                </li>
              </div>
              <img
                src={DemoPng}
                alt="Demo"
                className="img-fluid image-shadow-1"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Col>
            <Col xs={12} md={5}>
              <div className="demo-form">
                <p className="text-center size-30 second-color weight-700 mb-5">
                  Book A Demo
                </p>

                <Form className="demo-form-data" onSubmit={handleSubmit}>
                  <Row className="m-3">
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="email">
                        <Form.Label className="label-form">
                          Email <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="firstName">
                        <Form.Label className="label-form">
                          First Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-4" controlId="lastName">
                        <Form.Label className="label-form">
                          Last Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="title">
                        <Form.Label className="label-form">
                          Your Title <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="schoolName">
                        <Form.Label className="label-form">
                          Institute Name <span className="label-star">*</span>
                        </Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="schoolName"
                          value={formData.schoolName}
                          onChange={handleChange}
                          isInvalid={!!errors.schoolName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.schoolName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="mb-4" controlId="address">
                        <Form.Label className="label-form">Address</Form.Label>
                        <Form.Control
                          className="size-16 py-3"
                          size="lg"
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Label className="label-form">
                        Country <span className="label-star">*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Country select"
                        className="py-3"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        isInvalid={!!errors.country}
                      >
                        <option value="">Select</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.country}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <p className="text-center p-3 mk-footer-copyright">
                      Your information will be processed according to our
                      Privacy Policy. By clicking above, you authorize Scool.Ai
                      to contact and send you marketing communications.
                    </p>
                    <button className="main-button weight-600" type="submit">
                      Book
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Logos />
    </>
  );
}

export default BookDemo;
